import styled from '@emotion/styled'
import React from 'react'
import OfficialCommonBtn from './officialCommonBtn'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  &.modal-wrapper {
    padding: 15px 10px;

    .modal-window {
      max-width: 1000px;
      padding: 10px;

      .modal-content {
        position: relative;
        max-height: calc(100vh - 40px);

        img {
          margin: 0 0 10px;
          width: 100%;
          height: auto;
        }
      }
    }
  }
`
type Props = {
  /** クラス名 */
  className?: string
  /** ID名 */
  idName?: string
}

/**
 * アップデート情報表示コンテンツ（ポップアップ表示）
 */
const UpdateInfoContentV4 = (props: Props): JSX.Element => {
  const { className = '' } = props
  const routeParam = useRouteParam('/function/request-expense/')

  return (
    <Wrapper className={`modal-wrapper${className}`} id={props.idName}>
      <a href="#!" className="modal-overlay">
        &nbsp;
      </a>
      <div className="modal-window">
        <div className="modal-content">
          <img
            src="/images/official/feature/imgUpdateVer04.png"
            alt="4.0アップデート内容[経費精算]"
          />
          <OfficialCommonBtn
            size="mega"
            onClick={() => {
              // navigate('/function/request-expense/')
              window.open(routeParam, '_blank')
            }}
          >
            経費精算の詳細を見る
          </OfficialCommonBtn>
        </div>
        <a href="#!" className="modal-close">
          ×
        </a>
      </div>
    </Wrapper>
  )
}
export default UpdateInfoContentV4
